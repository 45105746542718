const CheckBoxCorrect = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7809 4.78008C14.4075 4.40664 13.802 4.40664 13.4286 4.78008L7.50218 10.7065L3.88242 7.08673C3.50898 6.7133 2.90352 6.7133 2.53008 7.08673C2.15664 7.46017 2.15664 8.06564 2.53008 8.43908L6.7462 12.6552C6.75952 12.6685 6.77314 12.6814 6.78703 12.6937C6.79953 12.7078 6.81251 12.7216 6.82598 12.735C7.19942 13.1085 7.80488 13.1085 8.17832 12.735L14.7809 6.13242C15.1544 5.75898 15.1544 5.15352 14.7809 4.78008Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckBoxCorrect
